import { useEffect, useState } from 'react';
import { NextSeo } from 'next-seo';
import { useLocalStorage, useWindowSize } from 'react-use';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { useTranslation } from '@hooks';
import { useAccount } from '@context/AccountContext';
import { useAuth } from '@context/AuthContext';
import { useNotification } from '@context/NotificationContext';
import PageHeader from '@components/common/Header/PageHeader';
import { GuestLogin, LoginForm, SignupForm } from '@components/auth';
import { CardIcon, CargoIcon, SecurityBadge } from '@components/icons';
import { NewButton } from '@components/ui';
import { Caption, Caption2 } from '@components/styles/Typography';
import AuthPageTitle from '../components/auth/AuthPageTitle';

const Login = () => {
    const { t } = useTranslation();
    const { open } = useNotification();
    const { width } = useWindowSize();
    const router = useRouter();
    const [activeTab, setActiveTab] = useState(
        router.query.tab ? router.query.tab : 'login',
    );

    const { addToFavoritesMutation } = useAccount();
    const { currentUser } = useAuth();

    const [history] = useLocalStorage('history');

    useEffect(() => {
        if (currentUser) {
            if (router.query.favoriteModelID) {
                addToFavoritesMutation.mutate({
                    modelID: Number(router.query.favoriteModelID),
                    token: currentUser,
                });
            }
            if (router?.query?.notifyMe) {
                // notifyMe(currentUser, Number(router.query.notifyMe));
                router.push({
                    pathname: router.query.cb,
                    query: {
                        notifyMe: router.query.notifyMe,
                    },
                });
                return;
            }
            if (history?.length > 0) {
                if (history[0] === '/checkout') {
                    router.push('/cart');
                    return;
                }
                if (router.query.cb) {
                    router.push(router.query.cb);
                } else {
                    router.push(history[1]);
                }
            } else {
                if (router.query.cb) {
                    router.push(router.query.cb);
                    return;
                }
                router.push('/');
            }
        }
    }, [currentUser]);

    useEffect(() => {
        if (router.query.cb === "/checkout") {
            setActiveTab("checkout");
        }else if (router.query.tab) {
            setActiveTab(router.query.tab);
        }
    }, [router.query.tab]);

    useEffect(() => {
        let closeAlertTimer;

        if (router.query.favoriteModelID) {
            open({
                type: 'warning',
                title: t('login'),
                description: t('favoriteLoginAlert'),
                alert: true,
            });
        }
        return () => {
            clearTimeout(closeAlertTimer);
        };
    }, [router.query.favoriteModelID]);

    return (
        <>
            <NextSeo title={`${t('ferventeTitle')} ${t('login')}`} />
            <AuthWrapper activeTab={activeTab}>
                {(activeTab === 'login' || activeTab === 'checkout') && (
                    <>
                        <Row>
                            <HeaderWrapper>
                                <PageHeader
                                    noBorderHeader
                                    onClick={() => router.push('/')}
                                />
                            </HeaderWrapper>
                            <AuthPageTitle title={t('userSignInUpper')} />
                            <LoginFormWrapper>
                                <LoginForm token={currentUser} />
                            </LoginFormWrapper>
                        </Row>
                        {activeTab === 'checkout' ? (
                            <Row>
                                <HeaderWrapper>
                                    <PageHeader noBorderHeader />
                                </HeaderWrapper>
                                <AuthPageTitle title={t('or')} />
                                <ButtonWrapper>
                                    <NewButton
                                        size="xlarge"
                                        type="secondary"
                                        content={t('newRegister')}
                                        handleClick={() =>
                                            setActiveTab('register')
                                        }
                                    />
                                </ButtonWrapper>
                                <NewRegister
                                    onClick={() => setActiveTab('guest')}
                                >
                                    <Caption>
                                        {t('continueWithoutRegister')}
                                    </Caption>
                                </NewRegister>
                            </Row>
                        ) : (
                            <Row>
                                <AuthPageTitle title={t('newUserUpper')} />
                                <ButtonAndFeaturesWrapper>
                                    <ButtonWrapper>
                                        <NewButton
                                            size="xlarge"
                                            type="secondary"
                                            content={t('createAccount')}
                                            handleClick={() =>
                                                setActiveTab('register')
                                            }
                                        />
                                    </ButtonWrapper>
                                    <AuthFeatureWrapper>
                                        {width > 1024 && (
                                            <FeatureItem>
                                                <Caption2>
                                                    {t('registerNow')}
                                                </Caption2>
                                            </FeatureItem>
                                        )}
                                        <FeatureItem>
                                            <CargoIcon />
                                            <Caption2>
                                                {t('freeShippingForTurkey')}
                                            </Caption2>
                                        </FeatureItem>
                                        <FeatureItem>
                                            <CardIcon />
                                            <Caption2>
                                                {t('installmentPaymentOption')}
                                            </Caption2>
                                        </FeatureItem>
                                        <FeatureItem>
                                            <SecurityBadge />
                                            <Caption2>
                                                {t('secureShop')}
                                            </Caption2>
                                        </FeatureItem>
                                    </AuthFeatureWrapper>
                                </ButtonAndFeaturesWrapper>
                            </Row>
                        )}
                    </>
                )}
                {activeTab === 'register' && (
                    <>
                        <PageHeaderWrapper>
                            <PageHeader
                                title={t('newUser')}
                                noBorderHeader
                                showDesktop
                            />
                        </PageHeaderWrapper>
                        <SignUpFormWrapper>
                            <SignupForm currentUser={currentUser} />
                        </SignUpFormWrapper>
                    </>
                )}
                {activeTab === 'guest' && (
                    <>
                        {width > 1024 ? (
                            <AuthPageTitle title={t('guestLogin')} />
                        ) : (
                            <PageHeader
                                title={t('guestLogin')}
                                noBorderHeader
                                onClick={() => setActiveTab('checkout')}
                            />
                        )}

                        <GuestLoginWrapper>
                            <GuestLogin />
                        </GuestLoginWrapper>
                    </>
                )}
            </AuthWrapper>
        </>
    );
};

const AuthWrapper = styled.div`
    padding-top: 140px;
    display: flex;
    flex-direction: ${({ activeTab }) =>
        activeTab === 'register' || activeTab === 'guest' ? 'column' : 'row'};
    gap: ${({ activeTab }) =>
        activeTab === 'register' || activeTab === 'guest' ? '0' : '150px'};
    min-height: 100vh;
    width: 64%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1024px) {
        padding: 70px 20px 0;
        flex-direction: column;
        gap: 0;
        width: 100%;
    }
`;
const LoginFormWrapper = styled.div`
    @media (max-width: 1024px) {
        padding: 0 20px 40px;
    }
`;
const Row = styled.div`
    width: 100%;
    max-width: 345px;

    @media (max-width: 1024px) {
        max-width: 100%;
    }
`;
const ButtonAndFeaturesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;
const AuthFeatureWrapper = styled.div`
    width: 100%;

    @media (max-width: 1024px) {
        width: 75%;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 20px;
    }
`;
const FeatureItem = styled.div`
    display: flex;
    align-items: center;
    gap: 11px;
    margin-bottom: 36px;
    margin-top: 10px;
`;
const ButtonWrapper = styled.div`
    width: 100%;

    @media (max-width: 1024px) {
        padding: 0 20px;
    }
`;
const SignUpFormWrapper = styled.div`
    width: 100%;
    max-width: 700px;

    @media (max-width: 1024px) {
        padding: 40px 20px 40px;
    }
`;
const PageHeaderWrapper = styled.div`
    @media (min-width: 1024px) {
        height: 43px;
        width: 190px;
        margin-bottom: 42px;
        padding: 0;

        > * {
            &:first-child {
                position: relative;
                top: 0;
                > * {
                    &:first-child {
                        left: 0;
                    }
                }
            }
        }
    }
`;
const NewRegister = styled.div`
    cursor: pointer;
    margin-top: 30px;

    @media (max-width: 1024px) {
        text-align: center;
    }
`;
const GuestLoginWrapper = styled.div`
    width: 100%;

    @media (max-width: 1024px) {
        margin-top: 70px;
        padding: 0 20px;
    }
`;
const HeaderWrapper = styled.div`
    @media (max-width: 1024px) {
        margin-bottom: 20px;
        > * {
            &:first-child {
                height: 43px;
            }
        }
    }
`;

export default Login;
