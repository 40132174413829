import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation, useUserIP } from '@hooks';
import { useAuth } from '@context/AuthContext';
import { useNotification } from '@context/NotificationContext';
import { signupSchema } from 'lib/validationSchemas';
import { InputWrapper, PhoneNumberInput } from '@components/common';
import { SignupTermsModal } from '@components/auth';
import {
    ClearConsent,
    MembershipAgreement,
    UserAgreement,
} from 'components/contracts';
import {
    Checkbox,
    CheckboxContainer,
    CheckboxLabel,
    Form,
    FormWrapper,
    LoginContainer,
    InputContainer,
} from 'components/styles/AuthStyles';
import { NewButton } from '@components/ui';
import { Caption2 } from '@components/styles/Typography';
import { signupEvent } from '@lib/gtm';

const { yupResolver } = require('@hookform/resolvers/yup');

const SignupForm = () => {
    const { t, locale, countryID } = useTranslation();
    const [ip] = useUserIP();
    const { registerMutation } = useAuth();
    const { open } = useNotification();
    const { mutate } = registerMutation;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(signupSchema) });

    const onSubmit = (data) => {
        mutate(
            {
                ...data,
                ip,
                countryID,
                lang: locale,
            },
            {
                onSuccess: (data, { allowPromotion }) => {
                    const userId = uuidv4();
                    signupEvent({
                        userId,
                        allowPromotion,
                    });
                    if (data?.success === false) {
                        open({
                            type: 'error',
                            title: t('emailUsedBefore'),
                        });
                    }
                },
                onError: () => {
                    open({
                        type: 'error',
                        title: t('emailUsedBefore'),
                    });
                },
            },
        );
    };

    return (
        <LoginContainer>
            <FormWrapper>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Row>
                        <InputContainer>
                            <InputWrapper
                                type="text"
                                placeholder={t('name')}
                                width="100%"
                                error={errors.first_name?.message}
                                paddingZero
                            >
                                <input {...register('first_name')} />
                            </InputWrapper>
                        </InputContainer>
                        <InputContainer>
                            <InputWrapper
                                type="text"
                                placeholder={t('surname')}
                                width="100%"
                                error={errors.last_name?.message}
                                paddingZero
                            >
                                <input {...register('last_name')} />
                            </InputWrapper>
                        </InputContainer>
                    </Row>
                    <Row>
                        <InputContainer>
                            <InputWrapper
                                type="email"
                                placeholder={t('email')}
                                width="100%"
                                error={errors.email?.message}
                                paddingZero
                            >
                                <input {...register('email')} />
                            </InputWrapper>
                        </InputContainer>

                        <InputContainer>
                            <InputWrapper
                                type="password"
                                placeholder={t('password')}
                                width="100%"
                                error={errors.password?.message}
                                paddingZero
                            >
                                <input
                                    type="password"
                                    {...register('password')}
                                />
                            </InputWrapper>
                        </InputContainer>
                    </Row>
                    <Row width="calc(50% - 20px)" alignSelf="flex-start">
                        <InputWrapper
                            placeholder={t('phone')}
                            error={errors.phoneNumber?.message}
                            mobile="100%"
                            margin="10px"
                        >
                            <PhoneNumberInput register={register} />
                        </InputWrapper>
                    </Row>
                    <CheckBoxesWrapper>
                        <CheckboxContainer width="100%">
                            <Checkbox
                                type="checkbox"
                                {...register('allowAgreement')}
                            />
                            <CheckboxLabel error={errors.allowAgreement}>
                                <Caption2>
                                    <SignupTermsModal
                                        title={t(
                                            'PersonalDataProtectionAuthority',
                                        )}
                                        content={<UserAgreement />}
                                    />
                                    ,
                                    <SignupTermsModal
                                        title={t('membershipAgreement')}
                                        content={<MembershipAgreement />}
                                    />{' '}
                                    {t('agreementAccept')}
                                </Caption2>
                            </CheckboxLabel>
                        </CheckboxContainer>
                        <CheckboxContainer width="100%">
                            <Checkbox
                                type="checkbox"
                                {...register('allowPromotion')}
                            />
                            <CheckboxLabel error={errors?.allowPromotion}>
                                <Caption2>
                                    {t('allowPromotionMessage')}{' '}
                                    <SignupTermsModal
                                        title={t('clearConsent')}
                                        content={<ClearConsent />}
                                    />{' '}
                                    {t('clearConsentText')}
                                </Caption2>
                            </CheckboxLabel>
                        </CheckboxContainer>
                        <CheckboxContainer width="100%">
                            <Checkbox
                                type="checkbox"
                                {...register('allowPromotionEmail')}
                            />
                            <CheckboxLabel error={errors.allowPromotionEmail}>
                                <Caption2>
                                    {t('allowPromotionEMail')}{' '}
                                    <SignupTermsModal
                                        title={t('clearConsent')}
                                        content={<ClearConsent />}
                                    />{' '}
                                    {t('clearConsentText2')}
                                </Caption2>
                            </CheckboxLabel>
                        </CheckboxContainer>
                    </CheckBoxesWrapper>
                    <ButtonWrapper>
                        <NewButton
                            size="xlarge"
                            type="primary"
                            content={t('createAccount')}
                            handleClick={handleSubmit(onSubmit)}
                        />
                    </ButtonWrapper>
                </Form>
            </FormWrapper>
        </LoginContainer>
    );
};
const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;

    @media (max-width: 1024px) {
        margin: 32px 0 0;
    }
`;
const Row = styled.div`
    display: flex;
    gap: 40px;
    width: ${({ width }) => width || '100%'};
    align-self: ${({ alignSelf }) => alignSelf || 'auto'};
    @media (max-width: 1024px) {
        display: block;
        width: 100%;
    }
`;
const CheckBoxesWrapper = styled.div`
    width: 90%;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;
    margin-top: 7px;
    @media (max-width: 1024px) {
        width: 100%;
        gap: 20px;
        margin-bottom: 0;
    }
    label {
        margin: 0;
    }
`;
export default SignupForm;
