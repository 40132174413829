import React from 'react';
import { useRouter } from 'next/router';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';

import { ArrowLeftIcon } from '@components/icons';
import { CartButton } from '@components/cart';
import { H2, Subtitle2, Text2 } from '@components/styles/Typography';

const PageHeader = ({
    title,
    productCount,
    showCartButton,
    noBorderHeader,
    showDesktop,
    onClick,
}) => {
    const router = useRouter();
    const { width } = useWindowSize();
    const isDesktop = width > 1024;
    const handleGoBack = () => {
        if (window.history.length <= 2) {
            router.push('/');
        } else {
            router.back();
        }
    };
    const onCartButtonClick = () => {
        router.push('/cart');
    };

    return (
        <HeaderWrapper
            noBorderHeader={noBorderHeader}
            showDesktop={showDesktop}
            isDesktop={isDesktop}
        >
            <LeftIconWrapper
                onClick={() => (onClick ? onClick() : handleGoBack())}
                data-testid="back-button"
            >
                <ArrowLeftIcon />
            </LeftIconWrapper>
            <TitleWrapper>
                {showDesktop && isDesktop ? (
                    <H2 uppercase>{title}</H2>
                ) : (
                    <Subtitle2 uppercase>{title}</Subtitle2>
                )}

                {productCount && <Text2>{productCount}</Text2>}
            </TitleWrapper>
            {showCartButton && (
                <CartButton onCartButtonClick={onCartButtonClick} />
            )}
        </HeaderWrapper>
    );
};

export default PageHeader;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    padding: ${({ isDesktop, showDesktop }) =>
        isDesktop && showDesktop ? '0' : '15px 20px'};
    border-bottom: ${({ noBorderHeader }) =>
        noBorderHeader ? 'none' : '1px solid rgb(0, 0, 0)'};
    background-color: white;

    @media (min-width: 1023px) {
        display: ${({ showDesktop }) => (showDesktop ? 'flex' : 'none')};
    }
`;
const LeftIconWrapper = styled.div`
    cursor: pointer;
    overflow: hidden;
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
`;
