import styled from 'styled-components';
import Link from 'next/link';
import { useNotification } from '@context/NotificationContext';
import { useForm } from 'react-hook-form';
import { guestLoginSchema } from 'lib/validationSchemas';
import { useTranslation } from '@hooks';
import { useAuth } from 'context/AuthContext';
import { InputWrapper } from '@components/common';
import { Caption, Text3 } from '@components/styles/Typography';
import { NewButton } from '@components/ui';

const { yupResolver } = require('@hookform/resolvers/yup');

const Description = () => {
    const { t } = useTranslation();
    return (
        <DescriptionWrapper>
            <Text3>
                <Link href="/login?cb=checkout" passHref>
                    <Caption>{t('login')}</Caption>
                </Link>{' '}
                {t('tryAnotherMail')}
            </Text3>
        </DescriptionWrapper>
    );
};
/* //TODO: notification open again, check it */

const GuestLogin = () => {
    const { t, locale, countryID } = useTranslation();
    const { guestLoginMutation } = useAuth();
    const { open } = useNotification();
    const { mutate } = guestLoginMutation;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(guestLoginSchema),
    });

    const onSubmit = (data) => {
        if(data?.email){
            mutate(
                {
                    countryID,
                    email: data.email?.toLowerCase(),
                    lang: locale,
                },
                {
                    onSuccess: (data) => {
                        if (data?.errors) {
                            open({
                                type: 'error',
                                title: t('mailAlreadExist'),
                                description: <Description />,
                            });
                        }
                    },
                },
            );
        }
    };

    return (
        <GuestLoginWrapper>
            <Wrapper onSubmit={handleSubmit(onSubmit)}>
                <InputWrapper
                    width="100%"
                    placeholder={t('email')}
                    error={errors?.email?.message}
                    paddingZero
                >
                    <input {...register('email')} />
                </InputWrapper>
                <ButtonWrapper>
                    <NewButton
                        size="xlarge"
                        type="primary"
                        content={t('continue')}
                        handleClick={onSubmit}
                    />
                </ButtonWrapper>
            </Wrapper>
        </GuestLoginWrapper>
    );
};
const GuestLoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 345px;
    @media (max-width: 1025px) {
        margin-top: 0;
        max-width: 100%;
    }
`;
const Wrapper = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 3px;
    background-color: #fff;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const DescriptionWrapper = styled.div`
    display: flex;
    margin-top: 3px;
    span {
        cursor: pointer;
        text-decoration: underline;
        white-space: nowrap;
    }
    p {
        margin: 0;
    }
`;

const ButtonWrapper = styled.div`
    width: 100%;
    margin-top: 60px;

    @media (max-width: 1024px) {
        margin-top: 75px;
    }
`;
export default GuestLogin;
