export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url) => {
    window.dataLayer.push({
        event: 'pageview',
        page: url,
    });
};

export const signupEvent = ({ userId, allowPromotion }) => {
    window.dataLayer.push({
        event: 'sign_up',
        user_id: userId,
        clear_consent: allowPromotion,
    });
};

export const addToFavouritesEvent = ({ product, currency }) => {
    window.dataLayer.push({
        event: 'add_to_wishlist',
        ecommerce: {
            currency, // UK tarafı için GBP
            value: product?.discounted_price[currency], // Fiyat ve indirimden sonraki değer
            items: [
                {
                    item_id: product?.id, // Ürün ID'si
                    item_name: product.name.tr, // Ürün adı
                    affiliation: 'Fervente Web Store', //
                    discount: product?.discounted_rate, // İndirim miktarı
                    index: product?.listing_point, // Ürün sıralaması
                    item_brand: 'Fervente', // Ürün markası
                    // item_category: 'Elbise', // Ürün kategorisi
                    // item_category2: 'Uzun', // Ürün kategorisi - örnek olarak yazılmıştır yoksa boşbırakılabilir.
                    // item_category3: 'Tül', // Ürün kategorisi - örnek olarak yazılmıştır yoksa boşbırakılabilir.
                    // item_category4: 'Gece', // Ürün kategorisi - örnek olarak yazılmıştır yoksa boşbırakılabilir.
                    // item_category5: 'Sonbahar', // Ürün kategorisi - örnek olarak yazılmıştır yoksa boşbırakılabilir.
                    item_list_id: product?.collection_name, // Ürün liste ID'si
                    item_list_name: product?.collection_name, // Ürün liste adı
                    // item_variant: 'Siyah', // Ürün renk desen seçeneği
                    price: product.price[currency], // Ürün fiyatı
                    quantity: 1, // Ürün adedi
                },
            ],
        },
    });
};

export const addToBasketEvent = ({ products, addedProducts }) => {
    const productIds = addedProducts.map((product) => product.productId);
    const lastAddedProducts = products.filter((product) =>
        productIds.includes(product.id),
    );
    const lastAddedProductsTotal = lastAddedProducts.reduce(
        (acc, product) => acc + product.discounted_price.USD * product.quantity,
        0,
    );
    window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
            currency: 'USD', // Para birimi
            value: lastAddedProductsTotal,
            items: lastAddedProducts.map((product, i) => ({
                item_id: product.id.toString(),
                item_name: product.name.en,
                affiliation: 'Fervente Web Store',
                coupon: '',
                discount: product.price.USD - product.discounted_price.USD,
                index: i,
                item_brand: 'Fervente',
                item_category: 'Wholesale Clothing',
                item_category2: '',
                item_category3: '',
                item_category4: '',
                item_category5: '',
                item_list_id: 'wholesale_clothing',
                item_list_name: 'Wholesale Clothing',
                item_variant: product.colour.name.tr,
                location_id: '',
                price: product.price.USD,
                quantity: product.quantity,
                stock_count: product.stock,
            })),
        },
    });
};
